<template>
    <div
        :class="['wit-treeselect', `wit-treeselect__${size}`, {'wit-treeselect__inline': inline}]"
        :data-selected="internalValue && internalValue.label ? internalValue.label : internalValue"
        :id="id"
    >
        <treeselect
            v-model="internalValue"
            :placeholder="placeholder"
            :multiple="multiple"
            :options="options"
            :value-format="valueFormat"
            :value-consists-of="valueConsistsOf"
            :limit="limit"
            :disabled="disabled"
            :normalizer="normalizer"
            :default-expand-level="defaultExpandLevel"
            :before-clear-all="beforeClearAll"
            @input="emitInput"
        >
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
                ><slot :name="slot" v-bind="scope"
            /></template>
        </treeselect>
    </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
    components: {
        Treeselect,
    },
    props: {
        value: {
            required: true,
        },
        options: {
            type: Array,
            default: () => [],
        },
        id: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        disabled: {
            type: Boolean,
        },
        size: {
            type: String,
            default: 'md',
            // 'lg', 'md', 'sm'
        },
        inline: {
            type: Boolean,
            default: false,
        },
        valueFormat: {
            type: String,
        },
        valueConsistsOf: {
            type: String,
        },
        limit: {
            type: Number,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        defaultExpandLevel: {
            type: [String, Number],
        },
        beforeClearAll: {
            type: Function,
        },
        normalizer: {
            type: Function,
        },
    },
    watch: {
        value() {
            this.internalValue = this.value
        },
    },
    created() {
        if (this.value) {
            this.internalValue = this.value
        }
    },
    data() {
        return {
            internalValue: null,
        }
    },
    methods: {
        emitInput() {
            if (!this.internalValue) {
                this.$emit('clear')
            }

            this.$emit('input', this.internalValue)
        },
    },
}
</script>

<style lang="scss"></style>
